import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="H" transform="translate(11.000000, 5.000000)">
        <path
          d="M 50 46 C 50 48 50 49 50 52 C 50 58 50 59 50 61 L 28 61 L 28 30 L 50 30 C 50 30 50 33 50 39 C 50 42 50 44 50 45 Z M 34 45 L 47 45 C 47 36 47 34 47 30 L 31 30 L 31 45 Z M 31 50 L 31 61 L 47 61 C 47 57 47 55 47 48 L 31 48 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
