import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 50 46 C 50 48 50 49 50 52 C 50 58 50 59 50 61 L 28 61 L 28 30 L 50 30 C 50 30 50 33 50 39 C 50 42 50 44 50 45 Z M 34 45 L 47 45 C 47 36 47 34 47 30 L 31 30 L 31 45 Z M 31 50 L 31 61 L 47 61 C 47 57 47 55 47 48 L 31 48 Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
